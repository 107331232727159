#nav a {
  font-size: 30px;
  margin-left: 50px;
}

.header-info {
  flex-direction: column;
}

.header-info .light-orange {
  margin-bottom: 15px;
}

#information {
  flex-direction: column;
  padding: 30px 60px;
}

.left,
.right {
  width: 100%;
  border-right: none !important;
  padding: 0 !important;
  font-size: 1.5em;
}

.bullet-title {
  font-size: 35px;
}

#thank-you {
  padding: 30px 60px 100px;
}
